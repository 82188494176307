import React from "react";
import { Card } from "react-bootstrap";
import ProjectLogs from "../../components/ProjectLogs/ProjectLogs";

const Dashboard = () => {
  return (
    <>
      <ProjectLogs />
      {/* <Card>Welcome, Home</Card> */}
    </>
  );
};

export default Dashboard;
