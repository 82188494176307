export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";

export const ADD_MILESTONE_REQUEST = "ADD_MILESTONE_REQUEST";
export const ADD_MILESTONE_SUCCESS = "ADD_MILESTONE_SUCCESS";
export const ADD_MILESTONE_FAIL = "ADD_MILESTONE_FAIL";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";
export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";

export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

export const ADD_LOG_FAIL = "ADD_LOG_FAIL";
export const ADD_LOG_REQUEST = "ADD_LOG_REQUEST";
export const ADD_LOG_SUCCESS = "ADD_LOG_SUCCESS";

export const GET_LOGS = "GET_LOGS";

export const UPDATE_LOG_SUCCESS = "UPDATE_LOG_SUCCESS";
export const UPDATE_LOG_FAIL = "UPDATE_LOG_FAIL";
export const UPDATE_LOG_REQUEST = "UPDATE_LOG_REQUEST";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
